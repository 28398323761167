import React from 'react'
import Intro from '../components/Intro/intro'
import Skills from '../components/Skills/Skills'
import SecondSkills from '../components/Skills/SecondSkills'
const Home = () => {
  return (
    <>
      <Intro/>
      <Skills/>
      <SecondSkills/>
1    </>
  )
}

export default Home
