import React from 'react'
import Accordion from '../components/Accordion/Accordion'

const FAQ = () => {
  return (
    <>
        <Accordion/>
    </>
  )
}

export default FAQ
