import React from 'react'
import WhoAmI from '../components/About/WhoAmI'

const About = () => {
  return (
    <>
    <WhoAmI/>
    </>
  )
}

export default About
