export const AccordionData =[
    {
        question: 'What technologies and programming languages do you specialize in ?',
        answer: 'A labracado'
    },
    {
        question: 'How do you handle revisions or changes during the development process?',
        answer: 'A labracado'
    },
    {
        question: ' Do you offer maintenance and support services after the website is launched?',
        answer: 'A labracado'
    },
    {
        question: ' Do you offer maintenance and support services after the website is launched?',
        answer: 'A labracado'
    },
    {
        question: ' Do you offer maintenance and support services after the website is launched?',
        answer: 'A labracado'
    },
    {
        question: ' What is your pricing structure? Do you charge by the hour, project, or another method?',
        answer: 'A labracado'
    },
];