import React from 'react'
import ProgectPage from '../components/Projects/ProgectPage'

const Projects = () => {
  return (
    <>
    <ProgectPage/>
    </>
  )
}

export default Projects
