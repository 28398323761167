import React from 'react'
import Toknow from '../components/Time/toKnow'

const Blog = () => {
  return (
    <>
      <Toknow/>
    </>
  )
}

export default Blog
